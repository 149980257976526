import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { db } from '@/lib/firebase';
import { useAuth } from '@/providers/auth-provider';
import { useSiteState } from '@/providers/state-provider';
import { stages } from '@/screens/reports/data/data';
import { ReportType } from '@/screens/reports/data/schema';
import { WizardNav } from '@/screens/reports/form/wizard-nav';
import { doc, onSnapshot } from 'firebase/firestore';
import { AnimatePresence, motion } from 'framer-motion';
import { AlertCircle } from 'lucide-react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

interface FormWizardContextType {
    stage: number;
    goPrev: () => void;
    goNext: () => void;
    report: ReportType | null;
    setLoading: (s: boolean) => void;
    loading: boolean;
}

const defaultValues = {
    stage: 0,
    goPrev: () => {},
    goNext: () => {},
    report: null,
    loading: false,
    setLoading: () => {}
}

export const FormWizardContext = createContext<FormWizardContextType>(defaultValues);

export const useFormWizard = () => {
    return useContext(FormWizardContext);
}

const FormWizardProvider = () => {

    const { id } = useParams();
    const { userData } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const { setShowCreditModal } = useSiteState();

    const [stage, setStage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState<ReportType | null>(null);

    const currentStage = () => {
        let s = stages.findIndex(s => location.pathname.endsWith(s.path));
        return (s < 0) ? 0 : s;
    }

    const goPrev = useCallback(() => {
        const s = currentStage();
        const prev = s === 0 ? 0 : s-1;
        setStage(prev);
        navigate(stages[prev].path);
    }, [location])

    const goNext = useCallback(() => {
        const s = currentStage();
        setStage(s+1);
        navigate(stages[s+1].path);
    }, [location])

    useEffect(() => {
        setStage(currentStage());
    }, [location, stage])

    useEffect(() => {
        if (id) {
            setLoading(true);
            const unsubscribe = onSnapshot(doc(db, "Reports", id), (snap) => {
                const data = snap.data() as ReportType;
                setReport({
                    ...report,
                    ...data
                });
                setLoading(false);
            });
            return unsubscribe;
        }
    }, [id])

    return (
        <FormWizardContext.Provider value={{
            stage,
            goPrev,
            goNext,
            report,
            loading,
            setLoading
        }}>
            <div className="space-y-6 p-10 pb-16 block">
                <div className="space-y-0.5">
                    <h2 className="text-2xl font-bold tracking-tight">Report Generator</h2>
                    <p className="text-muted-foreground">
                        Manage your account settings and set e-mail preferences.
                    </p>
                </div>
                <Separator className="my-6" />
                <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
                    <aside className="-mx-4 lg:w-1/5">
                        <WizardNav stages={stages} />
                    </aside>
                    <div className="flex-1 w-full">
                        {userData?.credits === 0 && (
                            <Alert variant="destructive" className='mb-4'>
                                <AlertCircle className="h-4 w-4" />
                                <AlertTitle>No Credits</AlertTitle>
                                <AlertDescription>
                                    You are able to save report data but generating the report will require at least 1 credit.
                                </AlertDescription>
                                <Button 
                                    className='mt-3' 
                                    variant="secondary"
                                    onClick={() => setShowCreditModal(true)}
                                >Purchase Credits</Button>
                            </Alert>
                        )}
                        <AnimatePresence>
                            <motion.div
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.4 }}
                                className='flex flex-col text-left gap-y-8'
                            >
                                <Outlet />
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </FormWizardContext.Provider>
    );
};

export default FormWizardProvider;