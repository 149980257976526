"use client"

import * as React from "react"

import { cn } from "@/lib/utils"
import { Icons } from "@/components/icons"

import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Link, useSearchParams } from "react-router-dom"
import { siteConfig } from "@/site-cofig"
import { toast } from "sonner"
import { GoogleAuthProvider, createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup } from "firebase/auth"
import { auth, formatAuthError } from "@/lib/firebase"
import { useAuth } from "@/providers/auth-provider"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { CheckCircle } from "lucide-react"

export interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

const registerFormSchema = z.object({
    email: z
      .string()
      .min(5, {
        message: "Please enter your email address",
      })
      .email("This is not a valid email."),
    password: z
      .string({
        required_error: "Please enter a password",
      })
      .min(8, {
        message: "Passwords must be at least 8 characters",
      })
  })
  
type AuthFormValues = z.infer<typeof registerFormSchema>;

export function RegisterForm({ className, ...props }: UserAuthFormProps) {

    const { user } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryEmail = searchParams.get("email");

    // This can come from your database or API.
    const defaultValues: Partial<AuthFormValues> = {
        email: queryEmail || "",
        password: "",
    }

    const form = useForm<AuthFormValues>({
        resolver: zodResolver(registerFormSchema),
        defaultValues,
        mode: "onChange",
    });

    const [isLoading, setLoading] = React.useState<boolean>(false)
    const [success, setSuccess] = React.useState<boolean>(false)

    async function onSubmit(data: AuthFormValues) {
        setSuccess(false);
        try {
            setLoading(true);

            const { user: userCredentials } = await createUserWithEmailAndPassword(auth, data.email, data.password);

            if (userCredentials && !userCredentials.emailVerified) {
                sendEmailVerification(userCredentials);
                setSuccess(true);
            }

            setLoading(false);
        } catch(e: unknown) {
            const msg = (e instanceof Error) ? e.message : "";
            toast.error(formatAuthError(msg), {
                description: "Please try again.",
            });
            setLoading(false);
        }
    }

    const googleRegister = async() => {
        try {
            setLoading(true);
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            const credential = GoogleAuthProvider.credentialFromResult(result);
            if (credential) {
                setLoading(false);
            } else {
                throw new Error("Unable to connect with Google.")
            }
        } catch(e: unknown) {
            const msg = (e instanceof Error) ? e.message : "";
            toast.error(formatAuthError(msg), {
                description: "Please try again.",
            });
            setLoading(false);
        }
    }

    return (
        <>
        <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
                Create an account
            </h1>
            <p className="text-sm text-muted-foreground">
                or <Link to="/" className="underline text-primary">login</Link> to your account
            </p>
        </div>
        <div className={cn("grid gap-6", className)} {...props}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="grid gap-2">
                        <div className="grid gap-1">
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input 
                                                type="email" 
                                                placeholder="name@example.com" 
                                                autoCapitalize="none"
                                                autoComplete="email"
                                                autoCorrect="off"
                                                disabled={isLoading}
                                                {...field} 
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="grid gap-1">
                            <FormField
                                control={form.control}
                                name="password"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input 
                                                type="password" 
                                                placeholder="Password" 
                                                disabled={isLoading}
                                                {...field} 
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        {success && (
                            <Alert variant="success">
                                <CheckCircle className="h-4 w-4" />
                                <AlertTitle>Account created.</AlertTitle>
                                <AlertDescription>
                                    Please check your email to verify 
                                </AlertDescription>
                            </Alert>
                        )}
                        <Button type="submit" disabled={isLoading}>
                            {isLoading && (
                                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Create Account
                        </Button>
                    </div>
                </form>
                <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                        <span className="w-full border-t" />
                    </div>
                    <div className="relative flex justify-center text-xs uppercase">
                        <span className="bg-background px-2 text-muted-foreground">
                            Or continue with
                        </span>
                    </div>
                </div>
                <Button onClick={googleRegister} variant="outline" size="lg" type="button" disabled={isLoading}>
                    {isLoading ? (
                        <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                    ) : (
                        <Icons.google className="mr-2 h-5 w-5" />
                    )}{" "}
                    Google
                </Button>
            </Form>
        </div>
        <p className="px-8 text-center text-sm text-muted-foreground">
            By creating an account, you agree to our{" "}
            <a
                href={siteConfig.landingUrl + "/terms"}
                target="_blank"
                className="underline underline-offset-4 hover:text-primary"
            >
                Terms of Service
            </a>{" "}
            and{" "}
            <a
                href={siteConfig.landingUrl + "/privacy"}
                target="_blank"
                className="underline underline-offset-4 hover:text-primary"
            >
                Privacy Policy
            </a>
            .
        </p>
        </>
    )
}