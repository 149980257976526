"use client"

import TransactionTable from "@/components/tables/transactions";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";

export function BillingScreen() {

    const [searchParams] = useSearchParams();

    const isCancelled = searchParams.get("canceled") === "true";
    const isSuccess = searchParams.get("success") === "true";

    useEffect(() => {
        console.log("isCancelled", isCancelled)
        console.log("isSuccess", isSuccess)
        const toastFunc = isCancelled ? toast.error : isSuccess ? toast.success : null;
        toastFunc?.(
            isCancelled 
                ? "Transaction Cancelled"
                : "Credits have been purchased"
        );
    }, [isCancelled, isSuccess])
    
    return (
        <div className="relative space-y-6">
            <TransactionTable />
        </div>
    )
}