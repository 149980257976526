import { appCheck } from "@/lib/firebase";
import { getToken } from "firebase/app-check";
import axios from 'axios';

export const postApi = async(endpoint: string, body: object) => {

    const token = (await getToken(appCheck)).token;
    const result = await axios.post(
        `${process.env.REACT_APP_API_URL}${endpoint}`, 
        body,
        { 
            headers: {
                "X-Firebase-AppCheck": token
            }
        },

    );
    return result;
}