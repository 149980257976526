import { QuestionType } from "@/screens/reports/data/data";
import { z } from "zod";

const defaultMessage = {
    message: "Please enter a value"
}

export const questionTypeSchema = (q: QuestionType) => {
    switch (q.type) {
        case "text":
            return q.optional || q.parent ? z.string().optional() : z.string().min(1, defaultMessage);
        case "select":
            return q.optional || q.parent ? z.string().optional() : z.string().min(1, defaultMessage);
        case "date":
            return q.optional || q.parent ? z.string().optional() : z.string().min(1, defaultMessage);
        case "number":
            return q.optional || q.parent ? z.string().optional() : z.string().min(1, defaultMessage);
        default:
            return z.string().optional();
    }
} 