import RecentReports from '@/screens/dashboard/recent-reports';
import { AvailableCredits, ReportCount } from '@/screens/dashboard/widgets';

const DashboardScreen = () => {
    return (
        <div className="flex-1 space-y-4 p-2 md:p-8 pt-6">
            <div className='space-y-4'>
                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 md:space-4">
                    <div className="md:w-1/2">
                        <RecentReports />
                    </div>
                    <div className='flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 w-full md:w-1/2'>
                        <div className="w-full">
                            <ReportCount />
                        </div>
                        <div className="w-full">
                            <AvailableCredits />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardScreen;