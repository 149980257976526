"use client"

import { ReportType } from '@/screens/reports/data/schema';
import React, { createContext, useContext, useState } from 'react';

interface AppCheckontextType {
    showCreditModal: boolean;
    setShowCreditModal: (state: boolean) => void;
    recentReports: ReportType[];
    setRecentReports: (state: ReportType[]) => void;
}

const StateContext = createContext<AppCheckontextType>({
    showCreditModal: false,
    setShowCreditModal: () => {},
    recentReports: [],
    setRecentReports: () => {}
});

export const useSiteState = () => {
    return useContext(StateContext);
}

export const StateProvider = ({ children }: { children: React.ReactNode }) => {

    const [showCreditModal, setShowCreditModal] = useState<boolean>(false);
    const [recentReports, setRecentReports] = useState<ReportType[]>([]);

    return (
        <StateContext.Provider value={{
            showCreditModal,
            setShowCreditModal,
            recentReports,
            setRecentReports
        }}>
            {children}
        </StateContext.Provider>
    );
};