import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select"
import { db } from "@/lib/firebase"
import { reportTypes } from "@/screens/reports/data/data"
import { useFormWizard } from "@/screens/reports/form"
import { FormActions } from "@/screens/reports/form/form-actions"
import { doc, updateDoc } from "firebase/firestore"
import { useEffect, useState } from "react"

export const ReportTypeStage = () => {

    const { report, goNext, setLoading, loading } = useFormWizard();

    const [error, setError] = useState(false);
    const [reportType, setReportType] = useState<string>(report?.report_type || "");

    const onSubmit = async() => {
        if (!reportType || !report?.id) {
            setError(true);
            return;
        }
        setLoading(true);
        await updateDoc(doc(db, "Reports", report?.id), {
            report_type: reportType
        });
        setLoading(false);
        goNext();
    }

    useEffect(() => { 
        if (report?.report_type) 
            setReportType(report?.report_type);
    }, [report?.report_type])

    return <Card className="w-full rounded-none">
        <CardHeader>
            <CardTitle>Report Type</CardTitle>
            <CardDescription>Select what type of report you wish to create.</CardDescription>
        </CardHeader>
        <CardContent>
            <div className="flex flex-col space-y-4">
                <div>
                    <Select
                        name="report_type"
                        value={reportType}
                        onValueChange={val => setReportType(val)}
                        disabled={loading}
                    >
                        <SelectTrigger >
                            <SelectValue placeholder="Select a report type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Report Type</SelectLabel>
                                {reportTypes.map((rt, x) => (
                                    <SelectItem key={`opt_rt_${x}`} value={rt.value}>{rt.label}</SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </CardContent>
        <CardFooter>
            <FormActions goNext={() => onSubmit()} />
        </CardFooter>
    </Card>
}