"use client"

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { useFormWizard } from "@/screens/reports/form"
import { FormActions } from "@/screens/reports/form/form-actions";
import { cn } from "@/lib/utils";
import { useCallback } from "react";
import { toast } from "sonner";
import { postApi } from "@/lib/fetch";
import { Textarea } from "@/components/ui/textarea";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/icons";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { useNavigate } from "react-router-dom";

const formSchema = z.object({
    claim_circumstances: z
      .string().min(2, {
        message: "Please enter the claim circumstances",
      }),
    resultant_damages: z
      .string().min(2, {
        message: "Please enter the resultant damages",
      }),
  });
  
type FormValues = z.infer<typeof formSchema>

export const FinalDetailsStage = () => {

    const navigate = useNavigate();
    const { loading, setLoading, report } = useFormWizard();

    const defaultValues: Partial<FormValues> = {
        claim_circumstances: report?.claim_circumstances || "",
        resultant_damages: report?.resultant_damages || "",
    }

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues,
        mode: "onChange",
    });

    const generate = useCallback(async() => {
        setLoading(true);

        const tid = toast.loading("Generating with Reportable AI...", {
            duration: Infinity
        });

        const res = await postApi("/ai/circumstances", {
            reportId: report?.id
        });

        if (res.data.data.claim_circumstances) {
            form.setValue("claim_circumstances", res.data.data.claim_circumstances);
        }
        if (res.data.data.resultant_damages) {
            const resDamagesArr = res.data.data.resultant_damages?.split("---") || [];
            const resDamages = resDamagesArr.map((chunk: string, index: number) => {
                return index === resDamagesArr.length -1 ? `\n\n${chunk}` : index === 0 ? chunk : `\n- ${chunk}`
            }).join("");
            form.setValue("resultant_damages", res.data.data.resultant_damages);
        }

        toast.success("Details Generated Successfully", {
            id: tid,
            duration: 4000
        });

        setLoading(false);
    }, [report])

    async function onSubmit(data: FormValues) {
        if (!report?.id) return;

        setLoading(true);

        await updateDoc(doc(db, "Reports", report?.id), {
            status: "complete"
        });

        setLoading(false);
        navigate("/v1/reports");
    }

    return <Card className="w-full rounded-none">
        <CardHeader>
            <CardTitle>Report Generator</CardTitle>
            <CardDescription>Final details to include before generating the report.</CardDescription>
        </CardHeader>
        <CardContent>
            <div className="flex flex-col space-y-4">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4">
                        <div className="col-span-2">
                            <FormField
                                control={form.control}
                                name="claim_circumstances"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Claim Circumstances</FormLabel>
                                        <FormControl>
                                            <div className="relative flex items-center">
                                                <Textarea rows={4} {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="col-span-2">
                            <FormField
                                control={form.control}
                                name="resultant_damages"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Resultant Damages</FormLabel>
                                        <FormControl>
                                            <div className="relative flex items-center">
                                                <Textarea rows={4} {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="col-span-2">
                            <Button variant="secondary" type="button" onClick={generate}>
                                {loading ? (
                                    <Icons.spinner className="animate-spin w-4 h-4" />
                                ) : (
                                    "Generate Using AI"
                                )}
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </CardContent>
        <CardFooter>
            <FormActions goNext={form.handleSubmit(onSubmit)} isFinal={true} />
        </CardFooter>
    </Card>
}