"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { ZodTypeAny, z } from "zod"

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { FormActions } from "@/screens/reports/form/form-actions"
import { useFormWizard } from "@/screens/reports/form"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import { CheckCircle } from "lucide-react"
import { Separator } from "@/components/ui/separator"
import { Textarea } from "@/components/ui/textarea"
import { doc, updateDoc } from "firebase/firestore"
import { db } from "@/lib/firebase"
import { QuestionType, reportTypeQuestions } from "@/screens/reports/data/data"
import { questionTypeSchema } from "@/lib/zod"
import { ReportType } from "@/screens/reports/data/schema"
import { useEffect } from "react"

export const CheckMark = ({ val, className }: { val: any, className?: string }) => (
  <CheckCircle className={cn("absolute w-4 h-4 right-10 text-emerald-600", val ? "" : "hidden", className ? className : "")} />
)

export function BaseQuestionsForm() {

  const { report, loading, setLoading, goNext, goPrev } = useFormWizard();

  const baseSchema = z.object({
    construction_type: z
      .string().min(1, {
        message: "Please select a Construction Type",
      }),
    property_height: z
      .string().min(1, {
        message: "Please select the property's height",
      }),
    property_condition: z
      .string().min(1, {
        message: "Please select the property's condition",
      }),
    roof_material: z
      .string().min(1, {
        message: "Please select the roof material",
      }),
    property_age: z
      .string().min(1, {
        message: "Please enter the property's age (in years)",
      }),
    roof_condition: z
      .string().min(1, {
        message: "Please select the roof's condition",
      }),
    inspection_date: z
      .string().min(1, {
        message: "Please enter the inspection date",
      }),
    inspection_time: z
      .string().min(1, {
        message: "Please enter the inspection time",
      }),
    inspection_by: z
      .string().min(1, {
        message: "Please enter the name of the inspector",
      }),
    weather: z
      .string().min(1, {
        message: "Please enter the weather at the inspection time",
      }),
    damage_occurrence: z
      .string().optional(),
    event_type: z
      .string().min(1, {
        message: "Please select an Event Type",
      }),
    maintenance_issues: z
      .string().optional(),
    detailed_scope: z
      .string().optional(),
  });

  const extraQuestions = report?.report_type ? reportTypeQuestions[report?.report_type] : [];
  let extraQuestionObjects: QuestionType[] = [];

  extraQuestions.forEach((q) => {
    extraQuestionObjects.push(q);
    if (q.subquestions)
      Object.keys(q.subquestions).forEach((key) => {
        const sq = q?.subquestions?.[key] || [];
        sq.forEach((sqq) => extraQuestionObjects.push({...sqq, parent: q.id, parentValue: key }))
      });
  })

  const extraSchema = Object.fromEntries(
    extraQuestionObjects.map((field) => [field.id, questionTypeSchema(field)])
  )

  const formSchema = baseSchema.merge(
    z.object(extraSchema) 
  ).superRefine((data, ctx) => {
    extraQuestions.filter(eq => eq.parent && eq.parentValue).forEach((eq) => {
      const parentValue = eq.parent ? data[eq.parent] : null;
      // if parent has value but not the right value for this question, pass over
      if (parentValue && parentValue !== eq.parentValue) return true;
      // if the parent has the right value for this child, return false if empty
      if ((parentValue && parentValue === eq.parentValue) && !!data[eq.id]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [eq.id],
          message: 'Please enter a value'
        })
      } 
      
    })
  });

  type FormValues = z.infer<typeof formSchema>;

  // This can come from your database or API.
  const values = {
    construction_type: report?.construction_type || "",
    property_height: report?.property_height || "",
    property_condition: report?.property_condition || "",
    roof_material: report?.roof_material || "",
    property_age: report?.property_age || "",
    roof_condition: report?.roof_condition || "",
    inspection_date: report?.inspection_date || "",
    inspection_time: report?.inspection_time || "",
    inspection_by: report?.inspection_by || "",
    damage_occurrence: report?.damage_occurrence || "",
    event_type: report?.event_type || "",
    maintenance_issues: report?.maintenance_issues || "",
    detailed_scope: report?.detailed_scope || "",
    weather: report?.weather || "",
    ...Object.fromEntries(
      extraQuestionObjects.map((field: QuestionType) => [field.id, report?.[field.id as keyof ReportType] || ""])
    )
  }

  const defaultValues: Partial<FormValues> = values;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: "onChange",
  });

  async function onSubmit(data: FormValues, callback: () => void) {
    if (!report?.id) {
      return;
    }
    setLoading(true);

    // remove values that aren't required anymore (user may have changed parent values)
    extraQuestionObjects
      .forEach((q) => {
        if (
          (q.parentValue && q.parent) && data[q.parent] !== q.parentValue
        ) {
          data[q.id] = "";
        }
      });

    await updateDoc(doc(db, "Reports", report?.id), {
      ...data
    });
    setLoading(false);
    callback();
  }

  useEffect(() => {
    form.reset(values);
  }, [report]);

  return (
    <Card className="w-full rounded-none">
      <CardHeader>
        <CardTitle>Base Assessment</CardTitle>
        <CardDescription>Some basic information about the building.</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit((data) => onSubmit(data, goNext))} className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <FormField
                  control={form.control}
                  name="construction_type"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Construction Type</FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600" : "")}>
                            <SelectValue placeholder="Select Construction Type" />
                            <CheckMark val={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Brick Veneer">Brick Veneer</SelectItem>
                          <SelectItem value="Double Brick">Double Brick</SelectItem>
                          <SelectItem value="Concrete Block">Concrete Block</SelectItem>
                          <SelectItem value="Fibre Cement">Fibre Cement</SelectItem>
                          <SelectItem value="Timber Weatherboard">Timber Weatherboard</SelectItem>
                          <SelectItem value="Aluminium Cladding">Aluminium Cladding</SelectItem>
                          <SelectItem value="Other">Other</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="property_height"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Property Height</FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600" : "")}>
                            <SelectValue placeholder="Select Property Height" />
                            <CheckMark val={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Single Storey">Single Storey</SelectItem>
                          <SelectItem value="Double Storey">Double Storey</SelectItem>
                          <SelectItem value="Multi Level">Multi Level</SelectItem>
                          <SelectItem value="Split Level">Split Level</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="property_condition"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Property Condition</FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600" : "")}>
                            <SelectValue placeholder="Select Property Condition" />
                            <CheckMark val={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Excellent">Excellent</SelectItem>
                          <SelectItem value="Good">Good</SelectItem>
                          <SelectItem value="Average">Average</SelectItem>
                          <SelectItem value="Poor">Poor</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="roof_material"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Roof Material</FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600" : "")}>
                            <SelectValue placeholder="Select Roof Material" />
                            <CheckMark val={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Colourbond">Colourbond</SelectItem>
                          <SelectItem value="Galvanised">Galvanised</SelectItem>
                          <SelectItem value="Roof Tiles">Roof Tiles</SelectItem>
                          <SelectItem value="Asbestos">Asbestos</SelectItem>
                          <SelectItem value="Other">Other</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="roof_condition"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Roof Condition</FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600" : "")}>
                            <SelectValue placeholder="Select Roof Condition" />
                            <CheckMark val={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Excellent">Excellent</SelectItem>
                          <SelectItem value="Good">Good</SelectItem>
                          <SelectItem value="Average">Average</SelectItem>
                          <SelectItem value="Poor">Poor</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="property_age"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Age of Property (years)</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="number" placeholder="e.g. 5" {...field} className={cn(field.value && !isNaN(Number(field.value)) ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Separator className="col-span-2 my-4" />
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="inspection_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Inspection Date</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="date" placeholder="e.g. 5" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="inspection_time"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Inspection Time</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="time" placeholder="e.g. 14:00" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="inspection_by"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Inspected By</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="weather"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Weather at Inspection Time</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="text" placeholder="e.g. Clear" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Separator className="col-span-2 my-4" />
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="damage_occurrence"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Damage Occurrence</FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600 pr-10" : "")}>
                            <SelectValue placeholder="Select Damage Occurrence" />
                            <CheckMark val={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Gradual">Gradual</SelectItem>
                          <SelectItem value="Sudden">Sudden</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="event_type"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Event Type</FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600 pr-10" : "")}>
                            <SelectValue placeholder="Select Event Type" />
                            <CheckMark val={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Impact">Impact</SelectItem>
                          <SelectItem value="Storm">Storm</SelectItem>
                          <SelectItem value="Flood">Flood</SelectItem>
                          <SelectItem value="Fire">Fire</SelectItem>
                          <SelectItem value="Escape of Liquid">Escape of Liquid</SelectItem>
                          <SelectItem value="Non-Insurable Event or Maintenance">Non-Insurable Event or Maintenance</SelectItem>
                          <SelectItem value="Other">Other</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2">
                <FormField
                  control={form.control}
                  name="maintenance_issues"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Any maintenance issues present?</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Textarea rows={3} {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2">
                <FormField
                  control={form.control}
                  name="detailed_scope"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Detailed Scope of Works for Insurable Repairs</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Textarea {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              
              {extraQuestions.length > 0 && <Separator className="col-span-2 my-4" />}
              {extraQuestions.map((qs, i) => {
                return <div key={`extraq_${i}`} className="col-span-2 lg:col-span-1">
                  <FormField
                    control={form.control}
                    name={qs.id}
                    render={({ field }) => (
                      <>
                      <FormItem>
                        <FormLabel>{qs.label}</FormLabel>
                        {qs.type === "select" ? (
                          <Select onValueChange={field.onChange} value={field.value}>
                            <FormControl>
                              <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600 pr-10" : "")}>
                                <SelectValue placeholder={qs.placeholder || "Please select"} />
                                <CheckMark val={field.value} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {qs.options?.map((qsopt, x) => (
                                <SelectItem key={`opt_${i}_${x}`} value={qsopt}>{qsopt}</SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        ) : (
                          <FormControl>
                            <div className="relative flex items-center">
                              <Input type={qs.type} placeholder={qs.placeholder} {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                              <CheckMark val={field.value} />
                            </div>
                          </FormControl>
                        )}
                        <FormMessage />
                      </FormItem>
                      {Object.keys(qs?.subquestions || {})?.map((answerKey) => {
                        const subqs = qs?.subquestions?.[answerKey];
                        if (!subqs) return null;
                        if (field.value === answerKey) {
                          return subqs.map((qssub, xs) => (
                            <FormField
                              key={`${answerKey}_subq_${xs}`}
                              control={form.control}
                              name={qssub.id}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>{qssub.label}</FormLabel>
                                  {qssub.type === "select" ? (
                                    <Select onValueChange={field.onChange} value={field.value}>
                                      <FormControl>
                                        <SelectTrigger className={cn("relative", field.value ? "border-2 border-emerald-600 pr-10" : "")}>
                                          <SelectValue placeholder={qssub.placeholder || "Please select"} />
                                          <CheckMark val={field.value} />
                                        </SelectTrigger>
                                      </FormControl>
                                      <SelectContent>
                                        {qssub.options?.map((qsopt, x) => (
                                          <SelectItem key={`subopt_${answerKey}_${xs}_${x}`} value={qsopt}>{qsopt}</SelectItem>
                                        ))}
                                      </SelectContent>
                                    </Select>
                                  ) : (
                                    <FormControl>
                                      <div className="relative flex items-center">
                                        <Input type={qssub.type} placeholder={qssub.placeholder} {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                                        <CheckMark val={field.value} />
                                      </div>
                                    </FormControl>
                                  )}
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          ))
                        }
                        return null;
                      })}
                      </>
                    )}
                  />
                </div>
              })}
            </form>
          </Form>
        </div>
      </CardContent>
      <CardFooter>
        <FormActions goNext={form.handleSubmit((data) => onSubmit(data, goNext))} saveAndBack={form.handleSubmit((data) => onSubmit(data, goPrev))} />
      </CardFooter>
    </Card>
  )
}