import { auth, db } from '@/lib/firebase';
import { User, onAuthStateChanged } from 'firebase/auth';
import { DocumentData, doc, onSnapshot } from 'firebase/firestore';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

type UserType = User | null;


interface AuthContextType {
    user: UserType;
    setUser: (data: UserType) => void;
    userData: DocumentData | null;
    setUserData: (data: DocumentData | null) => void;
}

const defaultValue = {
    user: null,
    setUser: () => {},
    userData: null,
    setUserData: () => {},
}

const AuthContext = createContext<AuthContextType>(defaultValue);

export const useAuth = () => {
    return useContext<AuthContextType>(AuthContext);
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState<UserType>(null);
    const [userData, setUserData] = useState<DocumentData | null>(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (userCredentials) => {
            const protectedRoute = location.pathname.includes("/v1/");
            if (userCredentials) {
                if (userCredentials.emailVerified) {
                    setUser(userCredentials);
                    if (!protectedRoute) navigate("/v1/");
                } else {
                    toast.info("You need to verify your email address");
                }
            } else {
                setUser(null);
                if (protectedRoute) navigate("/");
            }
        });

        return unsubscribe;
    }, [location])

    useEffect(() => {
        if (user) {
            const unsubscribe = onSnapshot(doc(db,"Users", user.uid), (snap) => {
                if (snap.exists()) {
                    setUserData(snap.data());
                }
            });
            return unsubscribe;
        }
    }, [user])
    
    return (
        <AuthContext.Provider value ={{
            user,
            setUser,
            userData,
            setUserData
        }}>
            {children}
        </AuthContext.Provider>
    );
};