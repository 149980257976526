"use client"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { useFormWizard } from "@/screens/reports/form"
import { useNavigate } from "react-router-dom"
import { StageType } from "@/screens/reports/data/data"

export function WizardNav({ stages }: { stages: StageType[] }) {

  const navigate = useNavigate();
  const { stage } = useFormWizard();

  return (
    <nav
      className="flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1"
    >
      {stages.map((item, index) => (
        <Button
          key={`formwizstep_${index}`}
          className={cn(
            "bg-accent text-foreground justify-start",
            stage === index
              ? "bg-primary text-white hover:bg-primary"
              : "hover:bg-accent",
            index < stage 
              ? "cursor-pointer"
              : "cursor-default"
          )}
          onClick={() => {
            if (index < stage) navigate(item.path)
          }}
        >
          <item.icon className="mr-1.5 w-5" />
          {item.title}
        </Button>
      ))}
    </nav>
  )
}