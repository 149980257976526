"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { toast } from "sonner"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { useAuth } from "@/providers/auth-provider"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { InfoIcon, MailIcon } from "lucide-react"
import { User, signInWithEmailAndPassword, updateEmail, updatePassword } from "firebase/auth"
import { Separator } from "@/components/ui/separator"
import { useCallback, useEffect, useState } from "react"
import { Icons } from "@/components/icons"
import { auth, db } from "@/lib/firebase"
import { doc, updateDoc } from "firebase/firestore"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"

const profileFormSchema = z.object({
    email: z
        .string({
            required_error: "Please enter a valid email",
        })
        .email(),
    company_name: z
        .string({
            required_error: "Please enter your company's name",
        }),
    address1: z
        .string({
            required_error: "Please enter the first line of address",
        }),
    address2: z
        .string()
        .optional(),
    city: z
        .string({
            required_error: "Please enter the city's name",
        }),
    post_code: z
        .string({
            required_error: "Please enter the post code",
        }),
    country: z
        .string({
            required_error: "Please enter the country",
        }), 
})

const passwordFormSchema = z.object({
    current_password: z
      .string({
        required_error: "Please enter your current passwword email",
      })
      .min(8, "Passwords must be at least 8 characters."),
    password: z
      .string({
        required_error: "Please enter a valid password",
      })
      .min(8, "Passwords must be at least 8 characters."),
  })

type ProfileFormValues = z.infer<typeof profileFormSchema>
type PasswordFormValues = z.infer<typeof passwordFormSchema>

export function SettingsForm() {

    const { user, userData } = useAuth();

    const isGoogleLogin = user?.providerData?.length === 1 && user?.providerData?.[0].providerId === "google.com";

    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState<File | null>(null);

    // This can come from your database or API.
    const defaultValues: Partial<ProfileFormValues> = {
        email: user?.email || "",
        company_name: userData?.company_name || "",
        address1: userData?.address1 || "",
        address2: userData?.address2 || "",
        city: userData?.city || "",
        post_code: userData?.post_code || "",
        country: userData?.country || "",
    }
    const defaultPassword: Partial<PasswordFormValues> = {
        password: ""
    }

    const form = useForm<ProfileFormValues>({
        resolver: zodResolver(profileFormSchema),
        defaultValues,
        mode: "onChange",
    })

    useEffect(() => {
        form.reset({
            email: user?.email || "",
            company_name: userData?.company_name || "",
            address1: userData?.address1 || "",
            address2: userData?.address2 || "",
            city: userData?.city || "",
            post_code: userData?.post_code || "",
            country: userData?.country || "",
        });
    }, [userData]);

    const passwordForm = useForm<PasswordFormValues>({
        resolver: zodResolver(passwordFormSchema),
        defaultValues: defaultPassword,
        mode: "onChange",
    })

    const onSubmit = useCallback(async(data: ProfileFormValues) => {
        try {
            if (!user?.uid) return;
            
            setLoading(true);

            if (data.email !== user.email && !isGoogleLogin) {
                await updateEmail(user as User, data.email);
                toast.success("Email Updated", {
                    description: "Please verify your email address."
                });
            }

            await updateDoc(doc(db, "Users", user.uid), {
                ...data,
                address2: data.address2 || ''
            });

            toast.success("Profile Updated");

            setLoading(false);
        } catch(e) {
            console.log(e);
            toast.error("An error occurred", {
                description: "Please try again."
            });
            setLoading(false);
        }
    }, [user, isGoogleLogin])

    const onPasswordSubmit = useCallback(async(data: PasswordFormValues) => {
        try {
            setLoading(true);
            await signInWithEmailAndPassword(auth, user?.email as string, data.current_password);
            await updatePassword(user as User, data.password);

            toast.success("Password Updated", {
                description: "You can now login with your new password."
            });
            setLoading(false);
        } catch(e) {
            console.log(e);
            toast.error("An error occurred", {
                description: "Please try again."
            });
            setLoading(false);
        }
    }, [user]);

    const uploadImage = useCallback(async() => {
        if (!user) return;
        if (!image) {
            toast.error("Please select an image to upload.")
            return;
        }
        const storage = getStorage();
        const storageRef = ref(storage, `users/${user?.uid}/${image.name}`);

        setLoading(true);

        const r = await uploadBytes(storageRef, image);
        const res = await getDownloadURL(r.ref);
        await updateDoc(doc(db, "Users", user.uid), {
            profileImage: res
        });
        
        setImage(null);
        setLoading(false);
    }, [user, image]);

    return (
        <>
            <div className="relative">
                <h3 className="text-lg font-medium">Company Image</h3>
                <p className="text-sm text-muted-foreground">
                    Add a logo to be included on your generated reports.
                </p>
                {userData?.profileImage && (
                    <div className="absolute right-0 top-0 flex h-fit w-fit">
                        <img
                            src={userData?.profileImage}
                            alt="User Profile Image"
                            className="h-[50px] w-auto"
                        />
                    </div>
                )}
            </div>
            <div className="flex flex-col space-y-4">
                <div className="relative flex justify-center items-center">
                    <Input 
                        type="file" 
                        disabled={loading} 
                        onChange={e => setImage(e.target.files?.[0] || null)}
                        accept="image/png,image/webp,image/jpeg" 
                    />
                    <Button 
                        type="button" 
                        disabled={loading || !image}
                        className="absolute right-1"
                        size="sm"
                        onClick={uploadImage}
                    >{loading ? <Icons.spinner /> : 'Upload'}</Button>
                </div>
                
            </div>
            <Separator className="my-4" />
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="email@example.com" type="email" disabled={loading || isGoogleLogin} {...field} />
                                    </FormControl>
                                    {!user?.emailVerified && 
                                    <Alert variant="destructive">
                                        <MailIcon className="h-4 w-4" />
                                        <AlertTitle>Email not verified</AlertTitle>
                                        <AlertDescription>
                                            Please check your inbox and verify your new email address.
                                        </AlertDescription>
                                    </Alert>
                                    }
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {isGoogleLogin && (
                            <Alert className="mt-3">
                                <InfoIcon className="h-4 w-4" />
                                <AlertTitle>Google Auth Account</AlertTitle>
                                <AlertDescription>
                                    You are unable to change your email as you login with a Google account. 
                                </AlertDescription>
                            </Alert>
                        )}
                    </div>
                    <div className="space-y-3">
                        <FormField
                            control={form.control}
                            name="company_name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Company Name</FormLabel>
                                    <FormControl>
                                        <Input type="text" disabled={loading} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="space-y-3">
                        <FormField
                            control={form.control}
                            name="address1"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Address Line 1</FormLabel>
                                    <FormControl>
                                        <Input type="text" disabled={loading} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="space-y-3">
                        <FormField
                            control={form.control}
                            name="address2"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Address Line 2</FormLabel>
                                    <FormControl>
                                        <Input type="text" disabled={loading} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="space-y-3">
                        <FormField
                            control={form.control}
                            name="city"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>City/Town</FormLabel>
                                    <FormControl>
                                        <Input type="text" disabled={loading} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="space-y-3">
                        <FormField
                            control={form.control}
                            name="post_code"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Post Code</FormLabel>
                                    <FormControl>
                                        <Input type="text" disabled={loading} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="space-y-3">
                        <FormField
                            control={form.control}
                            name="country"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Country</FormLabel>
                                    <FormControl>
                                        <Input type="text" disabled={loading} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button 
                        type="submit" 
                        disabled={loading}
                        className="col-span-2"
                    >{loading ? <Icons.spinner /> : 'Update'}</Button>
                </form>
            </Form>
            {!isGoogleLogin && (
                <Form {...passwordForm}>
                    <Separator />
                    <form onSubmit={passwordForm.handleSubmit(onPasswordSubmit)} className="space-y-8">
                        <div className="space-y-3">
                            <FormField
                                control={passwordForm.control}
                                name="current_password"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Current Password</FormLabel>
                                        <FormControl>
                                            <div className="relative">
                                                <Input type="password" placeholder="" disabled={loading} {...field} />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={passwordForm.control}
                                name="password"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>New Password</FormLabel>
                                        <FormControl>
                                            <div className="relative">
                                                <Input type="password" placeholder="" disabled={loading} {...field} />
                                                <Button 
                                                    type="submit" 
                                                    size="sm" 
                                                    className="absolute right-1 top-1"
                                                    disabled={loading}
                                                >{loading ? <Icons.spinner /> : 'Update'}</Button>
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </form>
                </Form>
            )}
        </>
    )
}