"use client"

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { useFormWizard } from "@/screens/reports/form"
import { FormActions } from "@/screens/reports/form/form-actions";
import { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import { postApi } from "@/lib/fetch";
import { Textarea } from "@/components/ui/textarea";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Info } from "lucide-react";
import { QuestionType, reportTypeQuestions } from "@/screens/reports/data/data";

export const SummaryStage = () => {

    const { goNext, loading, setLoading, report } = useFormWizard();
    
    const [summary, setSummary] = useState<string>(report?.summary || "");

    useEffect(() => {
        setSummary(report?.summary || "");
    }, [report?.summary])

    const onSubmit = useCallback(async() => {
        if (!summary) {
            toast.error("Please enter a summary");
            return;
        } else if (summary === report?.summary) {
            goNext();
            return;
        }
        try {
            setLoading(true);

            const tid = toast.loading("Analysing with Reportable AI...", {
                duration: Infinity
            });

            const res = await postApi("/ai/check-summary", {
                summary: summary,
                report_type: report?.report_type,
                reportId: report?.id
            });
            if (res.data.success) {
                toast.success("Summary analysed", {
                    id: tid,
                    duration: 4000
                });
                goNext();
            } else {
                toast.error("An error occurred", {
                    id: tid,
                    duration: 8000,
                    description: res.data.message
                });
            }
            
        } catch(e) {
            console.log(e);
            toast.error("An error occurred", {
                description: "Please try submitting again"
            });
        } finally {
            setLoading(false);
        }
    }, [summary, report])

    return <Card className="w-full rounded-none">
        <CardHeader>
            <CardTitle>Assessment Summary</CardTitle>
            <CardDescription>Include detail related to the damage and assessment. Our AI will analyse your summary and add detail, where necessary.</CardDescription>
        </CardHeader>
        <CardContent>
            <div className="flex flex-col space-y-4">
                <Textarea
                    rows={10} 
                    name="summary"
                    id="summary"
                    disabled={loading}
                    value={summary}
                    onChange={e => setSummary(e.target.value)}
                />
                {(typeof report?.report_type === "string" && reportTypeQuestions[report.report_type]?.length > 0) || report?.report_type === "building" && (
                <Alert>
                    <Info className="h-4 w-4" />
                    <AlertTitle className="text-sm">Things to include:</AlertTitle>
                    <AlertDescription>
                        <ul className="text-xs">
                            {report.report_type === "building" && (
                                <>
                                <li>What caused the damage</li>
                                <li>When did the damage occur</li>
                                <li>Measurements of the damaged areas</li>
                                <li>Are any other trades required to assess the damage?</li>
                                </>
                            )}
                            {typeof report?.report_type === "string" && reportTypeQuestions[report.report_type]?.map((question: QuestionType) => (
                                <li key={question.id}>{question.label}</li>
                            ))}
                        </ul>
                    </AlertDescription>
                </Alert>
                )}
            </div>
        </CardContent>
        <CardFooter>
            <FormActions goNext={() => onSubmit()} />
        </CardFooter>
    </Card>
}