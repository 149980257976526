import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useAuth } from '@/providers/auth-provider';
import { CoinsIcon, FileSpreadsheet } from 'lucide-react';

export const ReportCount = () => {

    const { userData } = useAuth();

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                    Reports Generated
                </CardTitle>
                <FileSpreadsheet  className="h-4 w-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{userData?.reportCount || 0}</div>
                <p className="text-xs text-muted-foreground">
                    {userData?.reportCount ? (
                        `Saved up to ${Number(userData?.reportCount * 0.33).toFixed(0)} hours.`
                    ) : (
                        "No reports generated yet."
                    )}
                </p>
            </CardContent>
        </Card>
    );
};

export const AvailableCredits = () => {

    const { userData } = useAuth();

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                    Available Credits
                </CardTitle>
                <CoinsIcon  className="h-4 w-4 text-muted-foreground"/>
            </CardHeader>
            <CardContent>
                <div className="text-2xl font-bold">{userData?.credits || 0}</div>
                <p className="text-xs text-muted-foreground">
                    {userData?.credits ? (
                        `You can generate ${userData.credits} more reports.`
                    ) : (
                        "No credits available."
                    )}
                </p>
            </CardContent>
        </Card>
    );
};