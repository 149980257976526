import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { CaretDownIcon, HamburgerMenuIcon } from "@radix-ui/react-icons"
import { useAuth } from "@/providers/auth-provider"
import { cn } from "@/lib/utils"
import { signOut } from "firebase/auth"
import { auth } from "@/lib/firebase"
import { Link } from "react-router-dom"
import { CoinsIcon, Cross, X } from "lucide-react"
import { useSiteState } from "@/providers/state-provider"
import { Sheet, SheetClose, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet"
import { SidebarInner } from "@/components/layout"

export function SiteHeader() {

    const { user, userData } = useAuth();
    const { setShowCreditModal } = useSiteState();

    return (
        <div className="border-b">
            <div className="flex h-16 items-center px-4">
                <div className="ml-auto flex justify-between w-full items-center space-x-4">
                    <div className="flex space-x-2 justify-between w-full">
                        <Sheet>
                            <SheetTrigger asChild>
                                <Button variant="outline" className="px-3 md:hidden">
                                    <HamburgerMenuIcon className="w-4 h-4" />
                                </Button>
                            </SheetTrigger>
                            <SheetContent className={"block md:hidden absolute w-[400px] bg-secondary-dark"}>
                                <SheetHeader>
                                    <SheetClose asChild>
                                        <X className="w-8 h-8 absolute top-2 right-2 z-[1]" color="white" />
                                    </SheetClose>
                                </SheetHeader>
                                <SidebarInner />
                            </SheetContent>
                        </Sheet>
                        <Button
                            variant="outline"
                            role="combobox"
                            aria-label="Select a team"
                            className={cn("flex flex-row justify-between px-3")}
                            onClick={() => setShowCreditModal(true)}
                        >
                            <CoinsIcon className="mr-2 h-5 w-5"/>
                            <span className="flex text-foreground/70 text-sm">{userData?.credits || 0} <span className="hidden md:block ml-1.5 text-ellipsis line-clamp-1">Credits</span></span>
                        </Button>
                    </div>
                    
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant="outline"
                                role="combobox"
                                aria-label="Select a team"
                                className={cn("w-[200px] flex flex-row justify-between px-3")}
                            >
                                <Avatar className="mr-2 h-5 w-5">
                                    <AvatarImage
                                        src={`https://avatar.vercel.sh/personal.png`}
                                        className="grayscale"
                                    />
                                    <AvatarFallback>{user?.displayName?.slice?.(0,1)}</AvatarFallback>
                                </Avatar>
                                <span className="text-ellipsis line-clamp-1">{user?.email}</span>
                                <CaretDownIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56" align="end" forceMount>
                            <DropdownMenuLabel className="font-normal">
                                <div className="flex flex-col space-y-1">
                                    <p className="text-sm font-medium leading-none">{user?.displayName || user?.email}</p>
                                    <p className="text-xs leading-none text-muted-foreground">
                                        {user?.email}
                                    </p>
                                </div>
                            </DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuGroup>
                                <DropdownMenuItem>
                                    <Link to="/v1/profile">Profile</Link>
                                </DropdownMenuItem>
                                <DropdownMenuItem>
                                    <Link to="/v1/profile/billing">Billing</Link>
                                </DropdownMenuItem>
                            </DropdownMenuGroup>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => signOut(auth)}>
                                Log out
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
        </div>
    )
}