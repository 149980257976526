"use client"

import { cn } from "@/lib/utils"
import { buttonVariants } from "@/components/ui/button"
import { Link, useLocation } from "react-router-dom"

import { DashboardIcon } from "@radix-ui/react-icons"
import { FileIcon, Settings } from "lucide-react"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
    isCollapsed: boolean;
}

type VariantType = "default" | "ghost";

const links = [
    {
      title: "Dashboard",
      icon: DashboardIcon,
      variant: "ghost",
      href: "/v1/",
      exact: true
    },
    {
      title: "Reports",
      icon: FileIcon,
      variant: "ghost",
      href: "/v1/reports",
      exact: false
    },
    {
      title: "Settings",
      icon: Settings,
      variant: "ghost",
      href: "/v1/profile",
      exact: false
    }
]

export function SidebarNav({ className, isCollapsed }: SidebarNavProps) {
    
    const { pathname } = useLocation();

    return (
        <div
            data-collapsed={isCollapsed}
            className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2"
        >
            <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
                {links.map((link, index) =>
                    isCollapsed ? (
                        <Tooltip key={index} delayDuration={0}>
                            <TooltipTrigger asChild>
                                <Link
                                    to={link.href}
                                    className={cn(
                                        buttonVariants({ variant: link.variant as VariantType, size: "icon" }),
                                        "h-9 w-9 bg-transparent hover:text-white text-white active:bg-primary",
                                        link.exact 
                                            ? link.href === pathname 
                                            : pathname.startsWith(link.href) 
                                                ? "bg-primary" 
                                                : "hover:bg-primary/50"
                                    )}
                                >
                                    <link.icon className="h-4 w-4" />
                                    <span className="sr-only">{link.title}</span>
                                </Link>
                            </TooltipTrigger>
                            <TooltipContent side="right" className="flex items-center gap-4">
                                {link.title}
                            </TooltipContent>
                        </Tooltip>
                    ) : (
                    <Link
                        key={`nav_${index}`}
                        to={link.href}
                        className={cn(
                            buttonVariants({ variant: link.variant as VariantType, size: "default" }),
                            "justify-start text-white hover:text-white py-6",
                            (link.exact 
                                ? link.href === pathname 
                                : pathname.startsWith(link.href))
                                    ? "bg-primary hover:bg-primary" 
                                    : "hover:bg-primary/50 hover:text-white active:bg-primary"
                        )}
                    >
                        <link.icon className="mr-2 h-5 w-5" />
                        {link.title}
                    </Link>
                    )
                )}
            </nav>
        </div>
    )
}