import { CheckCircle, EditIcon, FileIcon, FormInputIcon, ImageIcon, ListChecks, LucideIcon, TextIcon, User } from "lucide-react"

export const reportTypes = [
    {
        value: "building",
        label: "Building Assessment",
    },
    {
        value: "roof",
        label: "Roof Report",
    },
    {
        value: "electrical",
        label: "Electrical Report",
    },
    {
        value: "solar",
        label: "Solar Report",
    },
    {
        value: "plumbing",
        label: "Plumbing Report",
    },
]

type SubQuestionType = {[key: string]: QuestionType[]};

export interface QuestionType {
    id: string;
    label: string;
    type: string;
    options?: string[];
    subquestions?: SubQuestionType;
    optional?: boolean;
    placeholder?: string;
    parent?: string;
    parentValue?: string;
}

export const reportTypeQuestions: SubQuestionType = {
    solar: [],
    building: [],
    roof: [
        {
            id: "roof_pitch_standards",
            label: "Does the Roof Pitch meet Australian Standards?",
            type: "select",
            options: ["Yes", "No"],
            subquestions: {
                "No": [
                    {
                        id: "roof_pitch",
                        label: "Please state correct pitch for roof profile (degrees)",
                        type: "number",
                    }
                ]
            }
        },
        {
            id: "gutter_conditions",
            label: "Condition of Gutters, Valleys and Downpipes",
            type: "text"
        },
        {
            id: "ground_gutter_measurement",
            label: "Ground to Gutter Measurement",
            type: "text"
        },
        {
            
            id: "roof_measurement",
            label: "Approx. Overall Roof Measurement (m2)",
            type: "text"
        },
        {
            id: "roof_water_tight",
            label: "Is the Roof water-tight?",
            type: "select",
            options: ["Yes", "No"]
        },
        {
            id: "non_compliant_items",
            label: "Details of Non Compliant Items",
            type: "text",
            optional: true
        },
        {
            id: "roof_penetrations",
            label: "Roof Penetrations",
            type: "text",
            optional: true
        },
        {
            id: "safety_harness_required",
            label: "Was a safety harness required?",
            type: "select",
            options: ["Yes", "No"],
            subquestions: {
                "Yes": [
                    {
                        id: "safety_harness_image",
                        label: "Please provide a photo of the safety harness",
                        type: "image",
                    }
                ]
            }
        },
    ],
    electrical: [
        {
            id: "confirm_report_type",
            label: "Confirm Report Type",
            type: "select",
            options: ["Electrical", "Solar", "HVAC"],
            subquestions: {
                "Solar": [
                    {
                        id: "solar_system_size",
                        label: "Size of solar system and orientation",
                        type: "text",
                    },
                    {
                        id: "inverter_model",
                        label: "Inverter make/model",
                        type: "text",
                    },
                    {
                        id: "solar_inverter_exposed",
                        label: "Is the solar inverter ever fully exposed to the sun?",
                        type: "select",
                        options: ["Yes", "No"],
                    },
                    {
                        id: "solar_panel_model",
                        label: "Solar panel make/model",
                        type: "text",
                    },
                    {
                        id: "solar_panel_count",
                        label: "Number of solar panels",
                        type: "number",
                    },
                    {
                        id: "solar_array_earthed",
                        label: "Is the solar array earthed?",
                        type: "select",
                        options: ["Yes", "No"],
                    },
                    {
                        id: "hail_event_damage",
                        label: "Was the damage due to a hail event?",
                        type: "select",
                        options: ["Yes", "No"],
                    },
                ]
            }
        },
        {
            id: "affected_appliances",
            label: "If applicable, what are the model, brand, style and serial numbers of affected appliances?",
            type: "text",
            optional: true
        },
        {
            id: "installed_properly",
            label: "Has this product been installed properly?",
            type: "select",
            options: ["Yes", "No"],
            subquestions: {
                "No": [
                    {
                        id: "installed_properly_further_details",
                        label: "Please provide further details",
                        type: "text",
                    }
                ]
            }
        },
        {
            id: "electrical_test_results",
            label: "What are the electrical test results?",
            type: "text",
            optional: true
        },
        {
            id: "invasive_report_required",
            label: "Is a more invasive inspection required?",
            type: "select",
            options: ["Yes", "No", "HVAC"]
        },
    ],
    plumbing: [
        {
            id: "damage_cause",
            label: "Cause of Damage",
            type: "text",
        },
        {
            id: "damage_date",
            label: "When did the Damage Occur?",
            type: "date",
        },
        {
            id: "damage_from_plumbing",
            label: "Was there any visible damage to the property as a result of the Plumbing Issue?",
            type: "select",
            options: ["Yes", "No"]
        },
        {
            id: "repairs_to_plumbing",
            label: "Have any repairs or replacements been made to address the Plumbing Issue?",
            type: "select",
            options: ["Yes", "No"]
        },
        {
            id: "plumbing_affect_utilities",
            label: "Did the Plumbing Issue affect any other utilities in the property? (Gas, Electrical etc.)",
            type: "text",
        },
        {
            id: "damage_from_penetrations",
            label: "Are the related damages a direct result of any faulty or missing sealant around wall penetrations?",
            type: "select",
            options: ["Yes", "No"]
        },
        {
            id: "flood_test",
            label: "Flood Test",
            type: "select",
            options: ["Pass", "Fail", "N/A"]
        },
        {
            id: "sink_drainage_test",
            label: "Sink Drainage",
            type: "select",
            options: ["Pass", "Fail", "N/A"]
        },
        {
            id: "dishwasher_test",
            label: "Dishwasher",
            type: "select",
            options: ["Pass", "Fail", "N/A"]
        },
        {
            id: "pressure_test",
            label: "Pressure Test Cold/Hot Water Lines",
            type: "select",
            options: ["Pass", "Fail", "N/A"]
        },
        {
            id: "kpa_result",
            label: "KPA Result",
            type: "number",
        },
    ]
}

export const statuses = [
    {
      value: "complete",
      label: "Complete",
      icon: CheckCircle,
    },
    {
      value: "Draft",
      label: "Draft",
      icon: EditIcon,
    }
]

export interface StageType {
    key: string;
    title: string;
    icon: LucideIcon;
    path: string;
}

export const stages: StageType[] = [
    {
        key: "client_stage",
        title: 'Client Details',
        icon: User,
        path: "client"
    },
    {
        key: "report_type",
        title: 'Report Type',
        icon: FileIcon,
        path: "type"
    },
    {
        key: "images",
        title: 'Images',
        icon: ImageIcon,
        path: "images"
    },
    {
        key: "summary",
        title: 'Summary',
        icon: TextIcon,
        path: "summary"
    },
    {
        key: "base_questions",
        title: 'Base Questions',
        icon: FormInputIcon,
        path: "base"
    },
    {
        key: "final_details",
        title: 'Final Details',
        icon: ListChecks,
        path: "final"
    }
]

export const makesafeStages: StageType[] = [
    {
        key: "client_stage",
        title: 'Client Details',
        icon: User,
        path: "client"
    },
    {
        key: "images",
        title: 'Images',
        icon: ImageIcon,
        path: "images"
    },
    {
        key: "summary",
        title: 'Summary',
        icon: TextIcon,
        path: "summary"
    },
    {
        key: "base_questions",
        title: 'Base Questions',
        icon: FormInputIcon,
        path: "base"
    },
    {
        key: "final_details",
        title: 'Final Details',
        icon: ListChecks,
        path: "final"
    }
]