import { app } from "@/lib/firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export interface ImageType {
    url: string;
    path: string;
}

export interface UploadedImage {
    path: string;
    url: string;
}

const storage = getStorage(app);

export const uploadBlobs = async(path: string, images: Blob[]): Promise<UploadedImage[]> => {

    const results: UploadedImage[] = [];

    return new Promise(async(resolve) => {
        let x = 0;
        for await (const img of images) {
            const storageRef = ref(storage, `${path}/${x}`);
            await uploadBytes(storageRef, img);
            const url = await getDownloadURL(storageRef);
            results.push({
                path: `${path}/${x}`,
                url: url
            });
            x++;
        }
        resolve(results);
    });
}